export const SET_DISPLAY_COMPONENTS = "SET_DISPLAY_COMPONENTS";
export const SAVE_ADVERTS = "SAVE_ADVERTS";
export const SAVE_ADVERTS_PAGINATION = "SAVE_ADVERTS_PAGINATION";
export const FILTER_ADVERTS = "FILTER_ADVERTS";
export const SAVE_USER_ACCOUNTS = "MERCADO_LIBRE_ACCOUNTS";
export const ADVERTS_URL = "GET_ADVERTS_URL";
export const CLEAR_ADS_STATE = "CLEAR_ADS_STATE";
export const CLEAR_URL_STATE = "CLEAR_URL_STATE";
export const CLEAR_FILTER_STATE = "CLEAR_FILTER_STATE";
export const CHECK_ADVERT = "CHECK_ADVERT";
export const CHECK_ALL_ADS = "CHECK_ALL_ADS";
export const UNCHECK_ALL_ADS = "UNCHECK_ALL_ADS";
export const CHECK_ALL_ADS_FROM_PAGE = "CHECK_ALL_ADS_FROM_PAGE";
export const ADD_AD_TO_SELECTION_STATE = "ADD_AD_TO_SELECTION_STATE";
export const CLEAR_SELECTED_STATE = "CLEAR_SELECTED_STATE";
export const CHANGE_ADVERT_CATALOG = "CHANGE_ADVERT_CATALOG";
export const SAVE_ADS_POSITION_GRID = "SAVE_POSITION_GRID";
export const SAVE_ADS_POSITION_GRID_PAGINATION = "SAVE_POSITION_GRID_PAGINATION";
export const CLEAR_ADS_POSITION_GRID = "CLEAR_ADS_POSITION_GRID";
export const SAVE_ADS_POSITION_GRID_URL = "SAVE_ADS_POSITION_GRID_URL";
export const SAVE_SELECTED_ACCOUNTS = "SAVE_SELECTED_ACCOUNTS";
export const SAVE_FILTER_STRING = "SAVE_FILTER_STRING";
export const SAVE_QUESTIONS = "SAVE_QUESTIONS_TOBE_ANSWERED";
export const CLEAR_ANSWERED_QUESTIONS = "CLEAR_ANSWERED_QUESTIONS";
export const SAVE_ANSWERED_QUESTION_MSG = "SAVE_ANSWERED_QUESTION_MSG";
export const DISCOUNT_ACTION = "DISCOUNT_ACTION";
export const SAVE_FORM_POPUP_DATA = "SAVE_FORM_POPUP_DATA";
export const SAVE_FORM_POPUP_URL = "SAVE_FORM_POPUP_URL";
export const FETCH_HEADER_FOOTER_API_FORM_POPUP = "FETCH_HEADER_FOOTER_FORM_POPUP";
export const FETCH_REPLACE_TEXT_API_FORM_POPUP = "FETCH_REPLACE_TEXT_API_FORM_POPUP";
export const FETCH_ALTER_TEXT_API_FORM_POPUP = "FETCH_ALTER_TEXT_API_FORM_POPUP";
export const FETCH_ALTER_PRICE_API_FORM_POPUP = "FETCH_ALTER_PRICE_API_FORM_POPUP";
export const FETCH_ALTER_MANUFACTURING_TIME = "FETCH_ALTER_MANUFACTURING_TIME";
export const ACCOUNTS_SET_IS_LOADING = "ACCOUNTS_SET_IS_LOADING";
// CREATE ADVERT ACTION-TYPES
export const UPDATE_SPECS = "CREATE_ADVERT_UPDATE_ADVERT_SPECS";
export const SAVE_CATEGORY_LIST = "CREATE_ADVERT_SAVE_CATEGORY_OPTIONS";
export const SET_PREDICTED_CATEGORY = "SAVE_PREDICTED_CATEGORY_OBJECT";
export const CONFIRM_PREDICTED_CATEGORY = "SAVE_PREDICTED_AT_MAIN_CATEGORY_OBJECT";
export const CONFIRM_PATH_AS_CATEGORY = "CONFIRM_PATH_LAST_OBJECT_AS_DEFINED_CATEGORY";
export const SAVE_FORM_DATA = "SAVE_FORM_DATA";
export const CREATE_ADVERT_SAVE_VARIATION = "CREATE_ADVERT_SAVE_VARIATION";
export const CREATE_ADVERT_DELETE_VARIATION = "CREATE_ADVERT_DELETE_VARIATION";
export const CREATE_ADVERT_SAVE_DESCRIPTION = "CREATE_ADVERT_SAVE_DESCRIPTION";
export const FLEX_SHIPPING_ACTIVATE = "FLEX_SHIPPING_ACTIVATE";
export const FLEX_SHIPPING_DEACTIVATE = "FLEX_SHIPPING_DEACTIVATE";
export const FLEX_SHIPPING_CONFIG_COVERAGEZONE = "FLEX_SHIPPING_CONFIG_COVERAGEZONE";
export const FLEX_SHIPPING_CONFIG = "FLEX_SHIPPING_CONFIG";
export const SAVE_PROCESS_LIST = "SAVE_PROCESS_LIST";
export const CLEAR_PROCESS_LIST = "CLEAR_PROCESS_LIST";
export const SAVE_PROCESS_DETAILS = "SAVE_PROCESS_DETAILS";
export const CREATE_ADVERT_CATALOG_OPTIONS = "CREATE_ADVERT_SAVE_CATALOG_OPTIONS";
// ADVERTS REPLICATION ACTION-TYPES
export const REPLICATION_GO_TO_ADVERTS = "REPLICATION_GO_TO_ADVERTS";
export const REPLICATION_TOGGLE_LOADING = "REPLICATION_TOGGLE_LOADING";
export const REPLICATION_SAVE_QUERY = "REPLICATION_SAVE_QUERY";
export const REPLICATION_SELECT_ADVERT = "REPLICATION_SELECT_ADVERT";
export const REPLICATION_UNSELECT_ALL_FROM_PAGE = "REPLICATION_UNSELECT_ALL_FROM_PAGE";
export const REPLICATION_SELECT_ALL_FROM_PAGE = "REPLICATION_SELECT_ALL_FROM_PAGE";
export const REPLICATION_TOGGLE_SELECT_ALL_ADS = "REPLICATION_TOGGLE_SELECT_ALL_ADS";
export const REPLICATION_UPDATE_SEARCH_TYPE = "REPLICATION_UPDATE_SEARCHTYPE";
export const REPLICATION_BULK_UPDATE_DATA = "REPLICATION_BULK_UPDATE_DATA";
export const REPLICATION_SAVE_ADVERT_BEING_EDITED = "REPLICATION_SAVE_ADVERT_BEING_EDITED";
export const REPLICATION_SAVE_EDITED_ADVERT = "REPLICATION_SAVE_EDITED_ADVERT";
export const REPLICATION_UPDATE_ADVERT_DATA = "REPLICATION_UPDATE_ADVERT_DATA";
export const REPLICATION_UPDATE_ADVERT_DESCRIPTION = "REPLICATION_UPDATE_ADVERT_DESCRIPTION";
export const REPLICATION_RESET_STORE = "REPLICATION_RESET_STORE";
export const REPLICATION_SAVE_SELECTED_ACCOUNTS = "REPLICATION_SAVE_SELECTED_ACCOUNTS";
export const REPLICATION_SAVE_SHIPPING_MODES = "REPLICATION_SAVE_SHIPPING_MODES";
export const REPLICATION_SELECT_SHIPPING_MODE = "REPLICATION_SELECT_SHIPPING_MODE";
export const REPLICATION_UPDATE_PRICE_ACTIONS = "REPLICATION_UPDATE_PRICE_ACTIONS";
export const REPLICATION_SAVE_SHIPPING_TERM = "REPLICATION_SAVE_SHIPPING_TERM";
export const REPLICATION_SAVE_WARRANTY_TIME = "REPLICATION_SAVE_WARRANTY_TIME";
export const REPLICATION_SAVE_WARRANTY_TYPE = "REPLICATION_SAVE_WARRANTY_TYPE";
export const REPLICATION_SAVE_AVAILABLE_CREDITS = "REPLICATION_SAVE_AVAILABLE_CREDITS";
export const REPLICATION_TOGGLE_COPY_SAME_TITLE_ADS = "REPLICATION_TOGGLE_COPY_SAME_TITLE_ADS";
export const REPLICATION_TOGGLE_COPY_SAME_ACCOUNT_ADS = "REPLICATION_TOGGLE_COPY_SAME_ACCOUNT_ADS";
export const REPLICATION_GO_TO_CONFIRMATION = "REPLICATION_GO_TO_CONFIRMATION";
export const REPLICATION_SAVE_ADVERTS = "REPLICATION_SAVE_ADVERTS";
export const REPLICATION_SAVE_META = "REPLICATION_SAVE_META";
export const REPLICATION_SAVE_URL = "REPLICATION_SAVE_URL";
export const REPLICATION_TOGGLE_ALLOW_COPY_WARRANTY = "REPLICATION_TOGGLE_ALLOW_COPY_WARRANTY";
export const REPLICATION_TOGGLE_CREATE_WITHOUT_WARRANTY = "REPLICATION_TOGGLE_CREATE_WITHOUT_WARRANTY";
export const REPLICATION_REPLICATE_SELF = "REPLICATION_REPLICATE_SELF";
export const REPLICATION_TOGGLE_COPY_SHIPPING_TERMS = "REPLICATION_TOGGLE_COPY_SHIPPING_TERMS";
export const REPLICATION_SAVE_GTIN = "REPLICATION_SAVE_GTIN";
export const REPLICATION_RESET_FILTER = "REPLICATION_RESET_FILTER";
export const REPLICATION_SET_QUERY_NICKNAME = "REPLICATION_SET_QUERY_NICKNAME";
export const REPLICATION_SET_QUERY_KEYWORD = "REPLICATION_SET_QUERY_KEYWORD";
export const REPLICATION_SET_QUERY_CATEGORY = "REPLICATION_SET_QUERY_CATEGORY";
// CATALOG ACTION-TYPES
export const CATALOG_SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const CATALOG_SAVE_ADVERTISING = "CATALOG_SAVE_ADVERTISING";
export const CATALOG_TOGGLE_LOADING = "CATALOG_TOGGLE_LOADING";
export const CATALOG_SAVE_ADVERTS_META = "CATALOG_SAVE_ADVERTS_META";
export const CATALOG_CLEAR_ADVERTS_DATA = "CATALOG_CLEAR_ADVERTS_DATA";
export const CATALOG_FILTER_ADVERTS = "CATALOG_FILTER_ADVERTS";
export const CATALOG_SELECT_ADVERTISING = "CATALOG_SELECT_ADVERTISING";
export const CATALOG_SELECT_ALL_ADS = "CATALOG_SELECT_ALL_ADS";
export const CATALOG_SELECT_ALL_ADS_FROM_PAGE = "CATALOG_SELECT_ALL_ADS_FROM_PAGE";
export const CATALOG_OPT_IN = "CATALOG_OPT_IN";
export const CATALOG_SET_PRICE_TO_WIN_FILTERS = "CATALOG_SET_PRICE_TO_WIN_FILTERS";
export const CATALOG_SET_TAGS_FILTER = "CATALOG_SET_TAGS_FILTER";
// SALES ACTION-TYPES
export const SALES_TOGGLE_LOADING = "SALES_TOGGLE_LOADING";
export const SALES_TOGGLE_IS_CARD_OPEN = "SALES_TOGGLE_IS_CARD_OPEN";
export const SALES_SAVE_FILTER_STRING = "SALES_SAVE_FILTER_STRING";
export const SALES_SAVE_SELECTED_ACCOUNTS = "SALES_SAVE_SELECTED_ACCOUNTS";
export const SALES_RESET_FILTER_STRING = "SALES_RESET_FILTER_STRING";
export const SALES_SAVE_SALES = "SALES_SAVE_SALES";
export const SALES_SAVE_META = "SALES_SAVE_META";
export const SALES_SAVE_STATUS_FILTER = "SALES_SAVE_STATUS_FILTER";
export const SALES_SET_SELECTED_SALE = "SALES_SET_SELECTED_SALE";
export const SALES_COLLAPSE_ALL_CARDS = "SALES_COLLAPSE_ALL_CARDS";
export const SALES_SET_SELECT_ALL = "SALES_SET_SELECT_ALL";
export const SALES_CLEAN_SALES_STATE = "SALES_CLEAN_SALES_STATE";
export const SALES_FETCH_MSHOPS = "SALES_FETCH_MSHOPS";
// DASHBOARD ACTION-TYPES
export const DASHBOARD_TOGGLE_LOADING = "DASHBOARD_TOGGLE_LOADING";
export const DASHBOARD_DEFINE_PERIOD = "DASHBOARD_DEFINE_PERIOD";
export const DASHBOARD_SAVE_DATA = "DASHBOARD_SAVE_DATA";
export const DASHBOARD_SAVE_META = "DASHBOARD_SAVE_META";
export const DASHBOARD_SET_PERIOD = "DASHBOARD_SET_PERIOD_DATE";
// MODERATION ACTION-TYPES
export const MODERATION_SAVE_SELECTED_DATE = "MODERATION_SAVE_SELECTED_DATE";
export const MODERATION_TOGGLE_LOADING = "MODERATION_TOGGLE_LOADING";
export const MODERATION_SAVE_MODERATIONS_DATA = "MODERATION_SAVE_MODERATIONS_DATA";
export const MODERATION_SAVE_META = "MODERATION_SAVE_META";
export const MODERATION_SAVE_SELECTED_ACCOUNT = "MODERATION_SAVE_SELECTED_ACCOUNT";
export const MODERATION_SAVE_SELECTED_FROM_DATE = "MODERATION_SAVE_SELECTED_FROM_DATE";
export const MODERATION_SAVE_SELECTED_TO_DATE = "MODERATION_SAVE_SELECTED_TO_DATE";
export const MODERATION_SAVE_ADS_MODERATED_PER_ACCOUNT = "MODERATION_SAVE_ADS_MODERATED_PER_ACCOUNT";

// HIGH QUALITY ADVERTS ACTION-TYPES
export const HIGH_QUALITY_TOGGLE_LOADING = "HIGH_QUALITY_TOGGLE_LOADING";
export const HIGH_QUALITY_SAVE_ADVERT_ID = "HIGH_QUALITY_SAVE_ADVERT_ID";
export const HIGH_QUALITY_SAVE_ACCOUNT_ID = "HIGH_QUALITY_SAVE_ACCOUNT_ID";
export const HIGH_QUALITY_SAVE_ADVERT_DATA = "HIGH_QUALITY_SAVE_ADVERT_DATA";
export const HIGH_QUALITY_SAVE_NEW_TITLE = "HIGH_QUALITY_SAVE_NEW_TITLE";
export const HIGH_QUALITY_SAVE_NEW_DESCRIPTION = "HIGH_QUALITY_SAVE_NEW_DESCRIPTION";
export const HIGH_QUALITY_SAVE_NEW_GTIN = "HIGH_QUALITY_SAVE_NEW_GTIN";
export const HIGH_QUALITY_REMOVE_ADVERT_IMAGE = "HIGH_QUALITY_REMOVE_ADVERT_IMAGE";
export const HIGH_QUALITY_SAVE_NEW_ADVERT_IMAGE = "HIGH_QUALITY_SAVE_NEW_ADVERT_IMAGE";
export const HIGH_QUALITY_SAVE_ERRORS = "HIGH_QUALITY_SAVE_ERRORS";
// QUALITY DETAILS ACTION-TYPES
export const QUALITY_DETAILS_TOGGLE_LOADING = "QUALITY_DETAILS_TOGGLE_LOADING";
export const QUALITY_DETAILS_SAVE_DETAILS = "QUALITY_DETAILS_SAVE_DETAILS";
// TAGS ACTION-TYPES
export const TAGS_TOGGLE_LOADING = "TAGS_TOGGLE_LOADING";
export const TAGS_SAVE_TAG_LIST = "TAGS_SAVE_TAG_LIST";
export const TAGS_SAVE_TAG_PAGINATION = "TAGS_SAVE_TAG_PAGINATION";
export const TAGS_SAVE_SELECTED_AD_TAG_LIST = "TAGS_SAVE_SELECTED_AD_TAG_LIST";
export const TAGS_SAVE_SELECTED_ADS_TAG_LIST = "TAGS_SAVE_SELECTED_ADS_TAG_LIST";
export const TAGS_DELETE_SAVED_TAGS = "TAGS_DELETE_SAVED_TAGS";
export const TAGS_HIDE_TAG = "TAGS_HIDE_TAG";
export const TAGS_CLEAR_HIDDEN_TAGS = "TAGS_CLEAR_HIDDEN_TAGS";
export const TAGS_SAVE_SELECTED_TAGS = "TAGS_SAVE_SELECTED_TAGS";
export const TAGS_TOGGLE_MODAL_IS_OPEN = "TAGS_TOGGLE_MODAL_IS_OPEN";
export const TAGS_SAVE_MODAL_INPUT_VALUE = "TAGS_SAVE_MODAL_INPUT_VALUE";
export const TAGS_SAVE_SELECTED_ADS_TAGS = "TAGS_SAVE_SELECTED_ADS_TAGS";
export const TAGS_CREATE_NEW_TAG = "TAGS_CREATE_NEW_TAG";
export const TAGS_CLEAR_NEW_TAG_LIST = "TAGS_CLEAR_NEW_TAG_LIST";
export const TAGS_RESET_SELECTED = "TAGS_RESET_SELECTED";
export const TAGS_RESET_STATE = "TAGS_RESET_STATE";
// EDIT ADVERT VIEW ACTION-TYPES
export const EDIT_AD_SET_LOADING = "EDIT_AD_SET_LOADING";
export const EDIT_AD_SAVE_ADVERT_DATA = "EDIT_AD_SAVE_ADVERT_DATA";
export const EDIT_AD_UPDATE_FORM_DATA = "EDIT_AD_UPDATE_FORM_DATA";
export const EDIT_AD_TOGGLE_HIGHLIGHT = "EDIT_AD_TOGGLE_HIGHLIGHT";
export const EDIT_AD_SAVE_VIDEO_URL = "EDIT_AD_SAVE_VIDEO_URL";
export const EDIT_AD_RESET_FORM = "EDIT_AD_RESET_FORM";
export const EDIT_AD_RESET_STORE = "EDIT_AD_RESET_STORE";
export const EDIT_AD_SET_TOGGLE_ATTRIBUTES = "EDIT_AD_SET_TOGGLE_ATTRIBUTES";
// PAYMENT ACTION-TYPES
export const PAYMENT_SAVE_CLIENT_DATA = "PAYMENT_SAVE_CLIENT_DATA";
export const PAYMENT_SAVE_TOTAL_AND_ID = "payment/REQUEST_PAYMENT";
// SHOPEE
export const SHOPEE_SET_ADVERTS = "SET_SHOPEE_ADVERTS";
export const SHOPEE_SET_LOADING = "SHOPEE_SET_LOADING";
export const SHOPEE_SET_ADVERTS_PAGINATION = "SHOPEE_SET_ADVERTS_PAGINATION";
export const SHOPEE_SET_SALES_LIST = "SHOPEE_SET_SALES_LIST";
export const SHOPEE_SET_SELECTED_ACCOUNT_LIST = "SHOPEE_SET_SELECTED_ACCOUNT_LIST";
export const SHOPEE_SET_SALES_FILTER_STRING = "SHOPEE_SET_SALES_FILTER_STRING";
export const SHOPEE_SET_ADS_FILTER_STRING = "SHOPEE_SET_ADVERTS_FILTER_STRING";
export const SHOPEE_SET_SELECTED_ADVERTS = "SHOPEE_SET_SELECTED_ADVERTS";
export const SHOPEE_SET_SELECTED_ALL_ADS = "SHOPEE_SET_SELECTED_ALL_ADVERTS";
export const SHOPEE_SET_REPLICATION_COPY_SAME = "SHOPEE_SET_REPLICATION_COPY_SAME";
export const SHOPEE_RESET_STATES = "SHOPEE_RESET_STATES";
export const SHOPEE_SET_STOCK_FILTER = "SHOPEE -> SET STOCK FILTER";
// IMAGE STORAGE
export const SAVE_AVAILABLE_FILES = "SAVE_AVAILABLE_FILES";
export const SET_IMAGE_STORAGE_LOADING = "SET_IMAGE_STORAGE_LOADING";
export const SET_IMAGE_STORAGE_SELECT_FOLDER = "SET_IMAGE_STORAGE_SELECT_FOLDER";
export const SET_IMAGE_TO_UPLOAD = "SET_IMAGE_TO_UPLOAD";
export const SET_IMAGE_SEARCH = "SET_IMAGE_SEARCH";
export const SET_IMAGE_SET_SEARCH_RESULT = "SET_IMAGE_SET_SEARCH_RESULT";
export const SET_IMAGE_SAVE_PAGINATION = "SET_IMAGE_SAVE_PAGINATION";
export const SET_IMAGE_AVAILABLE_DIRECTORIES = "SET_IMAGE_AVAILABLE_DIRECTORIES";
export const SET_IMAGE_AVAILABLE_IMAGES = "SET_IMAGE_AVAILABLE_IMAGES";
export const SET_IMAGE_STORAGE_SELECTED_IMAGE = "SET_IMAGE_STORAGE_SELECTED_IMAGE";
export const SET_IMAGE_RESET_PAGINATION_STATE = "SET_IMAGE_RESET_PAGINATION_STATE";
export const SET_IMAGE_SELECT_ALL_PICTURES = "SET_IMAGE_SELECT_ALL_PICTURES";
export const SET_IMAGE_CLEAR_PICTURES_SELECTION = "SET_IMAGE_CLEAR_PICTURES_SELECTION";
// MSHOPS ACTION-TYPES
export const MSHOPS_SAVE_PRODUCT = "MSHOPS_SAVE_PRODUCT";
export const MSHOPS_SAVE_PRODUCT_META = "MSHOPS_SAVE_PRODUCT_META";
export const MSHOPS_TOGGLE_LOADING = "MSHOPS_TOGGLE_LOADING";
export const MSHOPS_CLEAR_PRODUCT_DATA = "MSHOPS_CLEAR_PRODUCT_DATA";
export const MSHOPS_SELECT_ALL_ADS = "MSHOPS_SELECT_ALL_ADS";
export const MSHOPS_SELECT_ALL_ADS_FROM_PAGE = "MSHOPS_SELECT_ALL_ADS_FROM_PAGE";
export const MSHOPS_SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const MSHOPS_SELECT_PRODUCT = "MSHOPS_SELECT_PRODUCT";
export const MSHOPS_TOGGLE_LOADING_STATUS = "MSHOPS_TOGGLE_LOADING_STATUS";
export const MSHOPS_TOGGLE_LOADING_SHIPPING = "MSHOPS_TOGGLE_LOADING_SHIPPING";
// ML CATEGORIES
export const SAVE_ML_CATEGORIES = "SAVE_ML_CATEGORIES";
export const SET_ML_CATEGORIES_LOADING = "SET_ML_CATEGORIES_LOADING";
export const SET_ML_CATEGORIES_PATH = "SET_ML_CATEGORIES_PATH";
export const RESET_PATH_ML_CATEGORIES = "RESET_PATH_ML_CATEGORIES";
