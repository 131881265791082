import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./config/ReactotronConfig";
import "react-picky/dist/picky.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import { icons } from "./assets/icons";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";

React.icons = icons;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.setUser({
  username: localStorage.getItem("@MeuML-UserName"),
  email: localStorage.getItem("@MeuML-UserEmail"),
});

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_KEY });

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
